.table{

    .img-wrapper{
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      margin: 0 auto;
    }

    .img-row{
      max-width: 60px;
      width: 60px;
    }

    .form-check{
        margin: 0;

        & label .form-check-sign::before,
        & label .form-check-sign::after{
            top: -17px;
            left: 4px;
        }
    }

    .btn{
        margin: 0;
    }

    small,.small{
      font-weight: 300;
    }

    .card-tasks .card-body &{
        margin-bottom: 0;

        > thead > tr > th,
        > tbody > tr > th,
        > tfoot > tr > th,
        > thead > tr > td,
        > tbody > tr > td,
        > tfoot > tr > td{
            padding-top: 0;
            padding-bottom: 0;
        }
    }

   > thead > tr > th{
       font-size: 14px;
       font-weight: $font-weight-bold;
       padding-bottom: 0;
       text-transform: uppercase;
       border: 0;
   }

   .radio,
   .checkbox{
       margin-top: 0;
       margin-bottom: 0;
       padding: 0;
       width: 15px;

       .icons{
           position: relative;
       }

        label{
            &:after,
            &:before{
                top: -17px;
                left: -3px;
            }
        }
   }
   > thead > tr > th,
   > tbody > tr > th,
   > tfoot > tr > th,
   > thead > tr > td,
   > tbody > tr > td,
   > tfoot > tr > td{
       padding: 12px 7px;
       vertical-align: middle;
   }

   .th-description{
       max-width: 150px;
   }
   .td-price{
       font-size: 26px;
       font-weight: $font-weight-light;
       margin-top: 5px;
       position: relative;
       top: 4px;
       text-align: right;
   }
   .td-total{
        font-weight: $font-weight-bold;
        font-size: $font-size-h5;
        padding-top: 20px;
        text-align: right;
    }

   .td-actions .btn{
    margin: 0px;
    }

    > tbody > tr{
        position: relative;
    }

		.tr-clickable {
			cursor: pointer
		}
}

.table-shopping{
    > thead > tr > th{
        font-size: $font-size-h6;
        text-transform: uppercase;
    }
    > tbody > tr > td{
        font-size: $font-paragraph;

        b{
            display: block;
            margin-bottom: 5px;
        }
    }
    .td-name{
        font-weight: $font-weight-normal;
        font-size: 1.5em;
        small{
            color: $dark-gray;
            font-size: 0.75em;
            font-weight: $font-weight-light;
        }
    }
    .td-number{
       font-weight: $font-weight-light;
       font-size: $font-size-h4;
   }
    .td-name{
        min-width: 200px;
    }
    .td-number{
        text-align: right;
        min-width: 170px;

        small{
            margin-right: 3px;
        }
    }

    .img-container{
        width: 120px;
        max-height: 160px;
        overflow: hidden;
        display: block;

        img{
            width: 100%;
        }
    }
}

.table-responsive{
  overflow: scroll;
  padding-bottom: 10px;
}

#tables .table-responsive{
    margin-bottom: 30px;
}

.table-hover>tbody>tr:hover{
  background-color: #f5f5f5;
}

.table-head-bordered {
  > thead > tr > th{
    border: 1px solid;
    border-color: $medium-gray;
  }
  > tbody > tr > td {
    border: 1px solid;
    border-color: $medium-gray;
  }
}

.table-head-bordered-bold {
  > thead > tr > th{
    border: 1px solid;
    border-color: $dark-gray;
  }
  > tbody > tr > td {
    border: 1px solid;
    border-color: $dark-gray;
  }
}

.materias {
  td {
    padding: 5px;
  }
}

.horarios {
  margin-top: 25px;
  margin-bottom: 25px;
  margin-left: 2%;
  width: 96%;
  text-align: center;
  td {
    border: 1px solid $medium-gray;
    height: 50px;
    min-width: 160px;
  }
  .td-hr {
    width: 8%;
    text-align: center;
    border: none;
  }
  .drop-div {
    width: 100%;
    height: 100%;
  }
}

.tboletim {
  margin-top: 25px;
  margin-bottom: 25px;
  text-align: center;
  width: 100%;
  th {
    border: 1px solid $medium-gray;
    background-color: $light-gray;
    height: 50px;
  }
  td {
    border: 1px solid $medium-gray;
    height: 50px;
    padding: 20px;
    min-width: 170px;
  }
  pre {
    white-space: pre-line;
    font-family: $sans-serif-family;
    font-size: 14px;
  }
}

.aulas {
  font-size: large;
  color: #555555;
  .td-name {
    cursor: pointer;
  }
  .td-dir {
    color: #FFD458;
    width: 30px;
  }
}
