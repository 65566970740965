body {
  color: $black-color;
  font-size: $font-size-base;
  font-family: $sans-serif-family;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.main {
  position: relative;
  background: $white-color;
}

/* Animations */
.nav-pills .nav-link,
.navbar,
.nav-tabs .nav-link,
.sidebar .nav a,
.sidebar .nav a i,
.animation-transition-general,
.tag,
.tag [data-role="remove"],
.animation-transition-general {
  @include transition($general-transition-time, $transition-ease);
}

//transition for dropdown caret
.dropdown-toggle:after,
.bootstrap-switch-label:before,
.caret {
  @include transition($fast-transition-time, $transition-ease);
}

.dropdown-toggle[aria-expanded="true"]:after,
a[data-toggle="collapse"][aria-expanded="true"] .caret,
.card-collapse .card a[data-toggle="collapse"][aria-expanded="true"] i,
.card-collapse .card a[data-toggle="collapse"].expanded i {
  @include rotate-180();
}

.button-bar {
  display: block;
  position: relative;
  width: 22px;
  height: 1px;
  border-radius: 1px;
  background: $white-bg;

  &+.button-bar {
    margin-top: 7px;
  }

  &:nth-child(2) {
    width: 17px;
  }
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}


.offline-doc {
  .navbar.navbar-transparent {
    padding-top: 25px;
    border-bottom: none;

    .navbar-minimize {
      display: none;
    }

    .navbar-brand,
    .collapse .navbar-nav .nav-link {
      color: $white-color !important;
    }
  }

  .footer {
    z-index: 3 !important;
  }

  .page-header {
    .container {
      z-index: 3;
    }

    &:after {
      background-color: rgba(0, 0, 0, 0.5);
      content: "";
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 2;
    }
  }
}

.fixed-plugin {
  .dropdown-menu li {
    padding: 2px !important;
  }
}

// badge color

.badge {
  &.badge-default {
    @include badge-color($default-color);
  }

  &.badge-primary {
    @include badge-color($primary-color);
  }

  &.badge-info {
    @include badge-color($info-color);
  }

  &.badge-success {
    @include badge-color($success-color);
  }

  &.badge-warning {
    @include badge-color($warning-color);
  }

  &.badge-danger {
    @include badge-color($danger-color);
  }

  &.badge-neutral {
    @include badge-color($white-color);
    color: inherit;
  }
}

.card-user {
  form {
    .form-group {
      margin-bottom: 20px;
    }
  }
}

.titulo-grafico {
  text-align: left;
  font-size: 2em;
  color: $dark-gray;
  font-size: 16px;
  line-height: 1.4em;
}

.dashboardItem {
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  width: 500px;
  height: 300px;
}

.dashboardItem div {
  text-align: center;
}

.bloco-tipo-avaliacao {
  margin-top: 25px;
  border-radius: 5px;
  padding: 20px;
  border: 1px solid $medium-gray;
}

.box-shadow {
  -webkit-box-shadow: 5px 5px 5px 0px rgba(222, 222, 222, 1);
  -moz-box-shadow: 5px 5px 5px 0px rgba(222, 222, 222, 1);
  box-shadow: 5px 5px 5px 0px rgba(222, 222, 222, 1);
  border-radius: 10px 10px 10px 10px;
  background-color: #FCFCFC;
  min-height: 320px;
  padding: 20px;
  text-align: center;
}

.titulo-plano {
  font-size: 30px;
  font-family: Impact, Charcoal, sans-serif;
  color: #7d7a79;
}

.preco-plano {
  font-size: 20px;
  font-family: Impact, Charcoal, sans-serif;
  color: #7d7a79;
}

.detalhes-plano {
  font-size: 10px;
  color: #7d7a79;
}

.card-planos {
  margin-left: 200px;
  margin-right: 200px;
  margin-bottom: 20px;
}

.card-planos-contrato {
  margin-left: 50px;
  margin-bottom: 30px;
}

.box-shadow-contrato {
  -webkit-box-shadow: 5px 5px 5px 0px rgba(222, 222, 222, 1);
  -moz-box-shadow: 5px 5px 5px 0px rgba(222, 222, 222, 1);
  box-shadow: 5px 5px 5px 0px rgba(222, 222, 222, 1);
  border-radius: 10px 10px 10px 10px;
  background-color: #FCFCFC;
  min-height: 200px;
  padding: 20px;
  text-align: center;
}

.dashboard-icon {
  width: 45px;
  height: 45px;
}

.noticias {
  margin-left: 5px;
  margin-right: 5px;

  .noticia {
    margin-top: 50px;
    border: 1px solid #DDDDDD;
    border-radius: 5px;
    padding: 15px;

    .titulo {
      @media (min-width: 991px) {
        font-size: 25px;
      }

      font-weight: bold;
      cursor: pointer;
      color: #555555;

      p:hover {
        color: black;
      }
    }

    .titulo-noticia {
      @media (min-width: 991px) {
        font-size: 25px;
      }

      font-weight: bold;
      color: #555555;
    }

    .data {
      color: #777777;
    }

    img {
      height: auto;
    }
  }
}

.mensagens-forum {
  .mensagem {
    width: 90%;
    margin-top: 20px;
    border: 1px solid #DDDDDD;
    border-radius: 5px;
    padding: 15px;
    background-color: #fff8eb;

    .data {
      color: #777777;
    }
  }

  .autor {
    width: 90%;
    background-color: #DCF8C6;
    margin-left: 10%;
  }
}

.aula {
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 50px;
  border: 1px solid #DDDDDD;
  border-radius: 25px;
  padding: 40px;
}

.frequencia-mes {
  table {
    .line-break {
      td {
        height: 15px;
        border: 0px;
      }
    }

    width: 100%;
    text-align: center;

    th {
      border: 1px solid #DDDDDD;
      height: 30px;
    }

    td {
      border: 1px solid #DDDDDD;
      height: 30px;
    }

    .nome-aluno {
      text-align: left;
      min-width: 350px;
      padding-left: 10px;
    }

    .dia-nao-util {
      background-color: #EEEEEE;
      min-width: 40px;
    }

    .dia-erro {
      background-color: $danger-color;
      min-width: 40px;
    }

    .dia-util {
      cursor: pointer;
      min-width: 40px;
    }

    .label {
      font-weight: bold;
    }
  }
}

.animated-textarea {
  display: block;
  width: 100%;
  overflow: hidden;
  resize: both;
  min-height: 40px;
  line-height: 20px;
}

.grupo-info {
  border: 1px solid $medium-gray;
  padding: 20px;
  border-radius: 5px;

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $medium-gray;
    opacity: 1;
    /* Firefox */
  }
}

.texto-tarefa {
  margin-top: 20px;
  border: 1px solid #DDDDDD;
  border-radius: 10px;
  padding: 40px;
}

.social-icons {
  .whatsapp {
    color: #25d366;
  }

  .facebook {
    color: #283E6B;
  }

  .twitter {
    color: #4DA6E9;
  }

  .disabled {
    color: #7c7c7c;
    cursor: default;
  }
}

.preserve-format {
  white-space: pre-wrap;

  &.add-margin {
    margin: 20px;
  }
}

.ava {
  .square_button {
    height: 130px;
    min-width: 250px;
    font-size: 30px;
    margin: 100px;
  }

  .turmas_virtuais {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 30px;

    .bnt_materia {
      height: 100px;
      width: 150px;
    }
  }
}

.radiobuttons {
  display: flex;
  height: 40px;

  &.has-danger {
    background-color: $danger-input-bg;
    border-radius: 5px;
    border: 1px solid $orange-color;
  }

  .radiobutton {
    margin: 0;
    align-self: center;
  }
}

.buttons-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.icone-dividir-nota {
  cursor: pointer;
}

.input-nota-exercicio {
  input::placeholder {
    opacity: .3;
  }

  .vermelha {
    color: #FF4C40;
  }

  .azul {
    color: #1F77D0;
  }

  min-width: 60px;
}

.valignceter {
  display: flex;
  flex-flow: wrap;
  align-items: center;
}

.topnav {
  overflow: hidden;
  background-color: #ffffff;
}

.topnav .login-container {
  float: right;
}

.topnav form {
  display: flex;
  flex-direction: row;
  margin-right: 10px;
}

.topnav .input-group {
  margin-top: 10px;
  margin-right: 10px;
  width: 300px;
}

.topnav .login-links {
  display: flex;
  justify-content: space-between;
  margin-right: 10px;
  margin-bottom: 10px;
}

.centerpage {
  margin-top: 50px;
}

/* Add responsiveness - On small screens, display the navbar vertically instead of horizontally */
@media screen and (max-width: 600px) {
  .topnav .login-container {
    float: none;
    padding: 14px;
  }

  .topnav .login-container button {
    float: none;
    display: block;
    text-align: left;
    width: 100%;
    margin: 0;
    padding: 14px;
    text-align: center;
  }

  .topnav form {
    display: block;
    margin-right: 0px;
  }

  .topnav .input-group {
    width: 100%;
  }

  .centerpage {
    margin-top: 15px;
  }

  .calendar {
    min-height: 3000px;
  }
}

.dropdown-sisa {
  overflow-y: visible;
}

.sisa-perfect-scrollbar {
  padding-bottom: 100px;
}

@media screen and (min-width: 601px) {
  .calendar {
    min-height: 850px;
  }
}

.month-container {
  margin-top: 20px;
}

.align-bottom {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.quill:not(.read-only) {
  .ql-editor {
    min-height: 500px;
  }
}

.ql-filebutton::before {
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"/><polyline points="14 2 14 8 20 8"/></svg>');
  background-size: contain;
  background-repeat: no-repeat;
  vertical-align: top;
}

.ql-tooltip.ql-editing {
  left: 20px !important;
}

.ql-editor .ql-video {
  margin: 0 auto;
  width: 853px;
  height: 480px;
}

.quill.has-danger .ql-container {
  background-color: $danger-input-bg !important;
}

.ql-editor {
  font-size: 14px;

  a {
    color: $primary-color !important;
  }
}

.quill.no-border .ql-container {
  border: none;
}

.breadcrumb {
  background-color: white;
}

/* Media query for mobile devices */
@media (max-width: 768px) {

  .table thead,
  .table tbody,
  .table tr,
  .table th,
  .table td {
    display: block;
    width: 100% !important;
    text-align: center !important;
  }

  .table thead {
    margin-bottom: 10px;
  }

  .table th,
  .table td {
    text-align: right;
    padding-left: 50%;
    position: relative;
  }

  .table th::before,
  .table td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 50%;
    padding-left: 15px;
    font-weight: bold;
    text-align: left;
  }

  .table tr {
    margin-bottom: 10px;
    border: 1px solid #ccc;
    /* Strong border for separation */
    border-radius: 5px;
  }

  /* Special styles for table header cells */
  .table th {
    background-color: #f8f9fa;
    border-bottom: 2px solid #dee2e6;
    text-align: center;
    padding: 10px;
    /* Optional: Add padding for better appearance */
  }

  .table th::before {
    content: attr(data-label);
    background-color: #f8f9fa;
    position: absolute;
    left: 0;
    width: 100%;
    padding-left: 15px;
    font-weight: bold;
    text-align: left;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-move {
  cursor: grab !important;
}

.usuarios-existentes-modal {
  max-width: 70%;
}

.album-container {
  width: 100%;
  aspect-ratio: 1 / 1;
  overflow: hidden;
  position: relative;
  margin: 0.5%;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .album-label {
    position: absolute;
    left: 10px;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    font-family: Arial, sans-serif;
  }

  .album-nome {
    top: 10px;
    font-weight: bold;
  }

  .album-quantidade-fotos {
    bottom: 50px;
  }

  .album-data {
    bottom: 10px;
  }

  /* Botão de menu dropdown */
  .album-menu-dropdown {
    position: absolute;
    margin: 0px;
    padding: 0px;
    top: 10px;
    right: 10px;
    border: none;
    font-size: 20px;
    padding: 0px !important;
    color: white;
  }

  .album-menu-dropdown-toggle {
    padding: 0px;
  }

  .album-menu-dropdown-toggle-button {
    background-color: rgba(0, 0, 0, 0.6);
    margin: 0px;
  }

}

.xxl-modal {
  min-width: 80%;    
}